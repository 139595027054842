<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-3 p-0 align-self-center font-weight-bold">
          <h5 class="d-inline mr-2 font-weight-bold">История платежей</h5>
          <i class="el-icon-refresh cursor-pointer"></i>
        </div>

        <div
          class="
            col-9
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <div class="text-center d-flex sorddata">
            <el-input
              class="mr-2"
              size="mini"
              placeholder="Найти"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="mr-5"
              size="mini"
              v-model="data_s"
              type="daterange"
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </div>
          <export-excel
            class="btn excel_btn mr-2"
            :data="list"
            worksheet="Пользователи"
            name="Пользователи.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <table
        class="table table-bordered no-border table-hover mr-0 ml-0 p-0"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.id_cod.show">{{ columns.id_cod.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.phone.show">{{ columns.phone.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.specialist.show">
              {{ columns.specialist.title }}
            </th>
            <th v-if="columns.status.show">{{ columns.status.title }}</th>
            <th v-if="columns.created_at2.show">
              {{ columns.created_at2.title }}
            </th>
            <th v-if="columns.amounts.show">{{ columns.amounts.title }}</th>
            <th v-if="columns.res_amount.show">
              {{ columns.res_amount.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.id_cod.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id_cod"
                class="id_input"
                :placeholder="columns.id_cod.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              >
              </el-input>
            </th>
            <th v-if="columns.phone.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone"
                :placeholder="columns.phone.title"
              >
              </el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.specialist.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.specialist"
                :placeholder="columns.specialist.title"
              >
              </el-input>
            </th>
            <th v-if="columns.status.show">
              <el-select
                v-model="filterForm.status"
                filterable
                clearable
                :placeholder="columns.status.title"
                size="mini"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.created_at2.show">
              <el-date-picker
                :placeholder="columns.created_at2.title"
                v-model="filterForm.created_at2"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.amounts.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.amounts"
                :placeholder="columns.amounts.title"
              >
              </el-input>
            </th>
            <th v-if="columns.res_amount.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.res_amount"
                :placeholder="columns.res_amount.title"
              >
              </el-input>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in list" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.id_cod.show">{{ user.id_cod }}</td>
            <td v-if="columns.name.show">{{ user.name }}</td>
            <td v-if="columns.phone.show">{{ user.phone }}</td>
            <td v-if="columns.created_at.show">{{ user.created_at }}</td>
            <td v-if="columns.specialist.show">{{ user.specialist }}</td>
            <td v-if="columns.status.show">
              {{ user.status === "active" ? "Активний" : "Не астивний" }}
            </td>
            <td v-if="columns.created_at2.show">{{ user.created_at2 }}</td>
            <td v-if="columns.amounts.show">{{ user.amounts }}</td>
            <td v-if="columns.res_amount.show">{{ user.res_amount }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-dropdown szie="mini">
                <el-button size="mini" icon="el-icon-setting" round>
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" size="mini">
                  <el-dropdown-item
                    command="edit"
                    icon="el-icon-edit el-icon--left"
                  >
                    Изменить</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="print"
                    icon="el-icon-printer el-icon--left"
                    >Печать</el-dropdown-item
                  >
                  <!-- <el-dropdown-item v-if="permissions.includes(name+'.'+'show')" command="show"  icon="el-icon-view el-icon--left"> Показать</el-dropdown-item> -->
                  <el-dropdown-item
                    command="back_material"
                    icon="el-icon-refresh-left el-icon--left"
                    >Возврат сырья</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="comments"
                    icon="el-icon-chat-line-square el-icon--left"
                    >Комментарий</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="delete"
                    icon="el-icon-delete el-icon--left"
                    @click="open"
                    ><el-button class="uadlit" @click="open"
                      >Удалить</el-button
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>

      <el-pagination
        class="mt-3 mypagination"
        background
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data_s: "",
      loadingData: false,
      filterForm: {
        search: "",
        id: "",
        id_cod: "",
        name: "",
        phone: "",
        created_at: "",
        specialist: "",
        status: "",
        created_at2: "",
        amounts: "",
        res_amount: "",
      },
      list: [
        {
          id: 2,
          id_cod: 123455,
          name: "Azimov Abdulaxadxon Axmotxon o'g'li",
          phone: "+998937307555",
          created_at: "2019-11-09 T06:28:49.000000Z",
          specialist: "Azimov Abdulaxadxon",
          status: "active",
          created_at2: "2019-11-19",
          amounts: 39000,
          res_amount: 2000,
        },
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        id_cod: {
          show: true,
          title: "ID",
          sortable: true,
          column: "id_cod",
        },
        name: {
          show: true,
          title: "ИФО Пациент",
          sortable: true,
          column: "name",
        },
        phone: {
          show: false,
          title: "Телефон",
          sortable: true,
          column: "phone",
        },
        created_at: {
          show: true,
          title: "Создан",
          sortable: true,
          column: "created_at",
        },
        specialist: {
          show: false,
          title: "Специалист",
          sortable: true,
          column: "specialist",
        },
        status: {
          show: true,
          title: "Статус",
          sortable: true,
          column: "status",
        },
        created_at2: {
          show: true,
          title: "Создан платные",
          sortable: true,
          column: "created_at2",
        },
        amounts: {
          show: true,
          title: "Общая сумма",
          sortable: true,
          column: "amounts",
        },
        res_amount: {
          show: false,
          title: "Остаточная сумма",
          sortable: true,
          column: "res_amount",
        },
        settings: {
          show: true,
          title: "Настройки",
          sortable: false,
          column: "settings",
        },
      },
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      tableID: false,
      tableUser: false,
    };
  },
  methods: {
    open() {
      this.$confirm("Это навсегда удалит файл. Продолжить?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Удалить завершено",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Удалить отменено",
          });
        });
    },
  },
};
</script>
